@charset "UTF-8";
body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}

.jq-checkbox {
  top: -1px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  border-radius: 3px;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
  cursor: pointer;
}

.jq-checkbox.checked .jq-checkbox__div {
  width: 12px;
  height: 12px;
  margin: 2px 0 0 2px;
  border-radius: 2px;
  background: #666;
  box-shadow: inset 0 -3px 6px #AAA;
}

.jq-checkbox.focused {
  border: 1px solid #08C;
}

.jq-checkbox.disabled {
  opacity: .55;
}

.jq-radio {
  top: -1px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  border-radius: 50%;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
  cursor: pointer;
}

.jq-radio.checked .jq-radio__div {
  width: 10px;
  height: 10px;
  margin: 3px 0 0 3px;
  border-radius: 50%;
  background: #777;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.7);
}

.jq-radio.focused {
  border: 1px solid #08C;
}

.jq-radio.disabled {
  opacity: .55;
}

.jq-file {
  width: 270px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.jq-file input {
  height: auto;
  line-height: 1em;
  cursor: pointer;
}

.jq-file__name {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 0 80px 0 10px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #FFF;
  box-shadow: inset 1px 1px #F1F1F1;
  font: 14px/32px Arial, sans-serif;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.jq-file__browse {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 0 10px;
  border-left: 1px solid #CCC;
  border-radius: 0 4px 4px 0;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/32px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF;
}

.jq-file:hover .jq-file__browse {
  background: #E6E6E6;
  background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
  background: linear-gradient(#F6F6F6, #E6E6E6);
}

.jq-file:active .jq-file__browse {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD;
}

.jq-file.focused .jq-file__name {
  border: 1px solid #5794BF;
}

.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}

.jq-number {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 36px 0 0;
}

.jq-number__field {
  width: 100px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
}

.jq-number__field:hover {
  border-color: #B3B3B3;
}

.jq-number__field input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 9px;
  border: none;
  outline: none;
  background: none;
  font: 14px Arial, sans-serif;
  color: #333;
  text-align: left;
  /* для Opera Presto */
  -moz-appearance: textfield;
}

.jq-number__field input::-webkit-inner-spin-button,
.jq-number__field input::-webkit-outer-spin-button {
  margin: 0;
  /* в каких-то браузерах присутствует отступ */
  -webkit-appearance: none;
}

.jq-number__spin {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 14px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-shadow: 1px 1px #FFF;
  cursor: pointer;
}

.jq-number__spin.minus {
  top: auto;
  bottom: 0;
}

.jq-number__spin:hover {
  background: #E6E6E6;
  background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
  background: linear-gradient(#F6F6F6, #E6E6E6);
}

.jq-number__spin:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD;
}

.jq-number__spin:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 11px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #999;
  border-left: 5px solid transparent;
}

.jq-number__spin.minus:after {
  top: 5px;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-bottom: none;
  border-left: 5px solid transparent;
}

.jq-number__spin.minus:hover:after {
  border-top-color: #000;
}

.jq-number__spin.plus:hover:after {
  border-bottom-color: #000;
}

.jq-number.focused .jq-number__field {
  border: 1px solid #5794BF;
}

.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}

.jq-number.disabled .jq-number__spin:after {
  border-bottom-color: #AAA;
}

.jq-number.disabled .jq-number__spin.minus:after {
  border-top-color: #AAA;
}

.jq-selectbox {
  vertical-align: middle;
  cursor: pointer;
}

.jq-selectbox__select {
  height: 32px;
  padding: 0 45px 0 10px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/32px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF;
}

.jq-selectbox__select:hover {
  background: #E6E6E6;
  background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
  background: linear-gradient(#F6F6F6, #E6E6E6);
}

.jq-selectbox__select:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD;
}

.jq-selectbox.focused .jq-selectbox__select {
  border: 1px solid #5794BF;
}

.jq-selectbox.disabled .jq-selectbox__select {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}

.jq-selectbox__select-text {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.jq-selectbox .placeholder {
  color: #888;
}

.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  border-left: 1px solid #CCC;
}

.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 14px;
  right: 12px;
  width: 0;
  height: 0;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.jq-selectbox:hover .jq-selectbox__trigger-arrow {
  border-top-color: #000;
}

.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
  border-top-color: #AAA;
}

.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font: 14px/18px Arial, sans-serif;
}

.jq-selectbox__search {
  margin: 5px;
}

.jq-selectbox__search input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 27px 6px 8px;
  border: 1px solid #CCC;
  border-radius: 3px;
  outline: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
  box-shadow: inset 1px 1px #F1F1F1;
  color: #333;
  -webkit-appearance: textfield;
}

.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px;
}

.jq-selectbox ul {
  margin: 0;
  padding: 0;
}

.jq-selectbox li {
  min-height: 18px;
  padding: 5px 10px 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  color: #231F20;
}

.jq-selectbox li.selected {
  background-color: #A3ABB1;
  color: #FFF;
}

.jq-selectbox li:hover {
  background-color: #08C;
  color: #FFF;
}

.jq-selectbox li.disabled {
  color: #AAA;
}

.jq-selectbox li.disabled:hover {
  background: none;
}

.jq-selectbox li.optgroup {
  font-weight: bold;
}

.jq-selectbox li.optgroup:hover {
  background: none;
  color: #231F20;
  cursor: default;
}

.jq-selectbox li.option {
  padding-left: 25px;
}

.jq-select-multiple {
  box-sizing: border-box;
  padding: 1px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #FFF;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/18px Arial, sans-serif;
  color: #333;
  cursor: default;
}

.jq-select-multiple.focused {
  border: 1px solid #5794BF;
}

.jq-select-multiple.disabled {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}

.jq-select-multiple ul {
  margin: 0;
  padding: 0;
}

.jq-select-multiple li {
  padding: 3px 9px 4px;
  list-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.jq-select-multiple li:first-child {
  border-radius: 3px 3px 0 0;
}

.jq-select-multiple li:last-child {
  border-radius: 0 0 3px 3px;
}

.jq-select-multiple li.selected {
  background: #08C;
  color: #FFF;
}

.jq-select-multiple li.disabled {
  color: #AAA;
}

.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
  background: #CCC;
  color: #FFF;
}

.jq-select-multiple li.optgroup {
  font-weight: bold;
}

.jq-select-multiple li.option {
  padding-left: 25px;
}

input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
  padding: 8px 9px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px Arial, sans-serif;
  color: #333;
}

input[type='search'].styler {
  -webkit-appearance: none;
}

textarea.styler {
  overflow: auto;
}

input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
  border-color: #B3B3B3;
}

input[type='email'].styler:focus,
input[type='password'].styler:focus,
input[type='search'].styler:focus,
input[type='tel'].styler:focus,
input[type='text'].styler:focus,
input[type='url'].styler:focus,
textarea.styler:focus {
  border-color: #CCC;
  border-top-color: #B3B3B3;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
  overflow: visible;
  padding: 8px 11px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  outline: none;
  background: #F5F5F5;
  background: -webkit-linear-gradient(#FFF, #E6E6E6);
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF;
  cursor: pointer;
}

button.styler::-moz-focus-inner,
input[type='button'].styler::-moz-focus-inner,
input[type='submit'].styler::-moz-focus-inner,
input[type='reset'].styler::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
  background: #E6E6E6;
  background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
  background: linear-gradient(#F6F6F6, #E6E6E6);
}

button.styler:not([disabled]):active,
input[type='button'].styler:not([disabled]):active,
input[type='submit'].styler:not([disabled]):active,
input[type='reset'].styler:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD;
}

button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: inherit !important;
  min-height: 1px;
  display: none;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15), 0px 4px 2px rgba(0, 0, 0, 0.05);
  display: block;
  height: 52px;
  width: 52px;
  line-height: 0px;
  font-size: 0px;
  border-radius: 50%;
  cursor: pointer;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 0.8;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.5;
}

.slick-prev:before,
.slick-next:before {
  content: '';
  width: 14px;
  height: 24px;
  background: url(../images/icon/big-arrow.svg) center center no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.slick-prev {
  left: -50px;
}

.slick-prev:before {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.slick-next {
  right: -50px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: #E7E7E7;
  display: block;
  height: 12px;
  width: 12px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li.slick-active button {
  background: #FFCE1B;
}
