/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    //padding:0 10% 0 0 !important;
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: inherit !important;
    min-height: 1px;
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


/* Slider */

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15), 0px 4px 2px rgba(0, 0, 0, 0.05);
    display: block;
    height: 52px;
    width: 52px;
    line-height: 0px;
    font-size: 0px;
    border-radius:50%;
    cursor: pointer;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        opacity: 0.8;
    }
    &.slick-disabled {
        opacity: 0.5;
    }
    &:before {
        content: '';
        width: 14px;
        height: 24px;
        background: url(../images/icon/big-arrow.svg) center center no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

.slick-prev {
    left: -50px;
    &:before {
        transform: translate(-50%, -50%) rotate(-180deg);
    }
}

.slick-next {
    right: -50px;
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: #E7E7E7;
            display: block;
            height: 12px;
            width: 12px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;
            padding:0;
            border-radius:50%;
            &:hover, &:focus {
                outline: none;
            }
        }
        &.slick-active button {
            background: #FFCE1B;
        }
    }
}
